.reason-description-row:not(.compact) {
    padding-top: 15px;
}
.reason-description-row span {
    float: left;
}
.reason-description-row span.deemphasize {
    color: darkGrey;
}
.reason-description-row span:not(.deemphasize) {
    padding-left: 5px;
}
