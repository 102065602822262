:root {

    /* Generic multi-use */
  
    /* Helix Brand Colors */
  
    /* Typography - all around most common text colors */
  
    /* Grey on Grey (general borders/backgrounds) */
  
    /* "Fill layout" background */
  
    /* Modal overlay */
  
    /* Forms */
  
    /* Links - text */
  
    /* Buttons - background & border */
  
    /* Footer - background & text */
  
    /* Sign in tabs */
    
    /* Copied over from V1 for Settings pages */
    /*  - should be reviewed to see if possible to cleanup */ /* can't have alpha channel - used for collapsed table borders */
}
.two-factor-wrapper {
  width: 420px;
  margin: 0 auto;
  padding: 10px;
  background-color: #f5f5f6;
  border: 2px solid #e3e3e3;
  border-radius: 4px;
}
.title {
  font-weight: bold;
  /* text-transform: uppercase; */
  font-size: 15px;
  text-align: center;
}
.qr-code-wrapper {
  text-align: center;
  padding: 10px
}
.qr-code-wrapper canvas {
    width: 50% !important;
    height: 50% !important;
    margin-left: auto;
    margin-right: auto;
}
.two-factor-success {
  margin-bottom: 0px;
  margin-top: 10px;
}
.well {
  margin: 0px;
}
