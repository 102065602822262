:root {

    /* Generic multi-use */
  
    /* Helix Brand Colors */
  
    /* Typography - all around most common text colors */
  
    /* Grey on Grey (general borders/backgrounds) */
  
    /* "Fill layout" background */
  
    /* Modal overlay */
  
    /* Forms */
  
    /* Links - text */
  
    /* Buttons - background & border */
  
    /* Footer - background & text */
  
    /* Sign in tabs */
    
    /* Copied over from V1 for Settings pages */
    /*  - should be reviewed to see if possible to cleanup */ /* can't have alpha channel - used for collapsed table borders */
}
.card:not(:first-child) {
    margin-top: 5px;
}
.card {
    text-align: left;
}
.panelTitle {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.requiredPipeline {
  font-size: 12px;
  margin-left: 2px;
}
.required {
  color: #666666;
}
.not-required {
    color: #d2d2d2;
}
.autoQcStatus {
    margin-right: 5px; 
}
.card span.autoQcStatus-QcFailed {
  color: #f55841;
}
.card span.autoQcStatus-QcPassed {
  color: #7acb71;
}