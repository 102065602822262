:import('../variables/colors.css') {
  i__const_orange_19: orange;
  i__const_mediumGray_20: mediumGray;
  i__const_green_21: green;
  i__const_red_22: red;
}
:export {
  colors: '../variables/colors.css';
  orange: i__const_orange_19;
  mediumGray: i__const_mediumGray_20;
  green: i__const_green_21;
  red: i__const_red_22;
}
.title {
  display: block;
  background: i__const_mediumGray_20;
  padding: 15px !important;
  text-align: center;
}
.batchTitle {
  float: left;
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 65%;
  text-align: left;
}
@media (max-width : 1199px) {
  .batchTitle {
    width: 100%;
    text-align: inherit;
  }
}
.limsPoolName {
  font-size: x-small
}
.limsPoolName span {
  float: left;
}
.batchStatus {
  float: right;
}
@media (max-width : 1199px) {
  .batchStatus {
    float: none;
  }
}
.card:not(:first-child) {
  margin-top: 5px;
}
.successCheck {
  color: i__const_green_21;
}
.failX {
  color: i__const_red_22;
}
.warningSign {
  color: i__const_orange_19;
}
.batchButton {
  margin-top: -7px;
}
.batchPass {
  margin-right: 10px;
}
