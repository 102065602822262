:import('../variables/colors.css') {
  i__const_lightGray_45: lightGray;
  i__const_mediumGray_46: mediumGray;
  i__const_darkGray_47: darkGray;
  i__const_darkBlue_48: darkBlue;
  i__const_red_49: red;
  i__const_green_50: green;
}
:export {
  colors: '../variables/colors.css';
  lightGray: i__const_lightGray_45;
  mediumGray: i__const_mediumGray_46;
  darkGray: i__const_darkGray_47;
  darkBlue: i__const_darkBlue_48;
  red: i__const_red_49;
  green: i__const_green_50;
}
table.quants {
  display: block;
}
.quants thead,
.quants tbody,
.quants tfoot,
.quants tr,
.quants td,
.quants th {
  display: block;
  width: 100%;
  text-align: left;
}
.quants tr:after {
  content: ' ';
  display: block;
  visibility: hidden;
  clear: both;
}
.quants tbody {
  max-height: 330px;
  overflow-y: auto;
}
.quants td,
.quants th {
  float: left;
}
.quants td {
  word-wrap: break-word;
  vertical-align: middle !important;
}
.quants td.passed,
.quants th.passed {
  width: 10%;
  text-align: center;
}
.quants td.analyzedAt,
.quants th.analyzedAt {
  width: 15%;
}
.quants td.type ,
.quants th.type {
  width: 20%;
}
.quants td.concentration,
.quants th.concentration {
  width: 15%;
  text-align: center;
}
.quants td.comments,
.quants th.comments {
  width: 40%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.passed-ok {
  color: i__const_green_50;
}
.passed-fail {
  color: i__const_red_49;
}
.borderless td,
.borderless th {
  border: none !important;
}
.tableHeader {
  margin-top: 2px;
  font-size: 10px;
  font-weight: normal;
  text-transform: uppercase;
  color: i__const_darkGray_47;
}
.quants tbody tr {
  font-size: 12px;
}
.quants td.empty {
  padding: 20px !important;
  text-align: center !important;
  vertical-align: middle !important;
  color: i__const_mediumGray_46;
}
