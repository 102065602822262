:import('../variables/colors.css') {
  i__const_mediumGray_29: mediumGray;
  i__const_darkGray_30: darkGray;
}
:export {
  colors: '../variables/colors.css';
  mediumGray: i__const_mediumGray_29;
  darkGray: i__const_darkGray_30;
}
table.sample-states {
  display: block;
}
.sample-states thead,
.sample-states tbody,
.sample-states tfoot,
.sample-states tr {
  display: block;
  width: 100%;
}
.sample-states tr:after {
  content: ' ';
  display: block;
  visibility: hidden;
  clear: both;
}
.sample-states td,
.sample-states th {
  float: left;
  width: 18%;
}
.sample-states td.completionState ,
.sample-states th.completionState {
  width: 28%;
}
.sample-states th.completionState {
  visibility: hidden;
}
.sample-states td {
  word-wrap: break-word;
  vertical-align: middle !important;
}
.sample-states th {
  overflow: hidden;
  text-overflow: ellipsis;
}
.sample-states tbody tr {
  font-size: 12px;
  border-bottom: 1px i__const_mediumGray_29 solid;
}
.sample-states td.empty {
  padding: 20px !important;
  text-align: center;
  vertical-align: middle !important;
  color: i__const_mediumGray_29;
}
.borderless td,
.borderless th {
  border: none !important;
}
.tableHeader {
  margin-top: 2px;
  font-size: 10px;
  font-weight: normal;
  text-transform: uppercase;
  color: i__const_darkGray_30;
}
