:import('../variables/colors.css') {
  i__const_red_38: red;
}
:export {
  colors: '../variables/colors.css';
  red: i__const_red_38;
}
.tooltip-sampleFlag > div:first-child {
  border-right-color: yellow;
}
.tooltip-sampleFlag > div:last-child {
  max-width: 300px;
  width: auto;
  text-overflow: ellipsis;
  display: block;
  border-radius: 0px;
}
span.sampleFlag {
  display: block;
}
