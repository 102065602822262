:import('./variables/colors.css') {
  i__const_background_44: background;
}
:export {
  colors: './variables/colors.css';
  background: i__const_background_44;
}
:root {

    /* Generic multi-use */
  
    /* Helix Brand Colors */
  
    /* Typography - all around most common text colors */
  
    /* Grey on Grey (general borders/backgrounds) */
  
    /* "Fill layout" background */
  
    /* Modal overlay */
  
    /* Forms */
  
    /* Links - text */
  
    /* Buttons - background & border */
  
    /* Footer - background & text */
  
    /* Sign in tabs */
    
    /* Copied over from V1 for Settings pages */
    /*  - should be reviewed to see if possible to cleanup */ /* can't have alpha channel - used for collapsed table borders */
}
:global(.rc-collapse-anim-active) {
  -webkit-transition: height 0.2s ease-out;
  transition: height 0.2s ease-out;
}
:global(.rc-collapse-header) {
  background-color: white;
  height: 44px;
  line-height: 44px;
  padding-left: 15px;
  padding-right: 15px;
  color: black;
  font-weight: bold;
  cursor: pointer;
  font-style: normal !important;
}
:global(.rc-collapse-item > .rc-collapse-header .arrow:before) {
  font-style: normal;
  float: right;
  content: '\25BC';
}
:global(.rc-collapse-item-active > .rc-collapse-header .arrow:before) {
  content: '\25B2';
}
:global(.rc-collapse-content) {
  overflow: hidden;
}
:global(.rc-collapse-content-box) {
  border-top: 1px solid #d2d2d2;
}
:global(.rc-collapse-content-inactive) {
  display: none;
}
* {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
body,
input,
textarea,
select,
button {
  font-family: TTNorms, Helvetica, Arial, sans-serif;
  font-weight: 400;
  line-height: 1.5; /* which computes to 24px */
}
body {
  margin: 0;
  background-color: i__const_background_44;
}
.app {
  padding: 8px 24px;
  height: 100%;
  width: 100%;
  font-weight: normal;
  font-smoothing: antialiased;
}
.app-root {
  margin-left: auto;
  margin-right: auto;
  max-width: 1400px;
  padding-left: 30px;
  padding-right: 30px;
}
