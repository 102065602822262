ul.yield-list {
  padding-left: 0px;
  padding-right: 0px
}

ul.yield-list li {
  border-bottom: 2px dotted #ccc;
  line-height: 8px;
  text-align: right;
  clear: both;
  margin: 12px 0 0 0
}

ul.yield-list li span {
  display: inline-block;
  border: 2px solid #fff;
  padding: 0;
  margin: 0 0 -2px 0
}

ul.yield-list li span.description {
  float: left;
}

div.yield-row {
  white-space: nowrap;
}
