:root {

    /* Generic multi-use */
  
    /* Helix Brand Colors */
  
    /* Typography - all around most common text colors */
  
    /* Grey on Grey (general borders/backgrounds) */
  
    /* "Fill layout" background */
  
    /* Modal overlay */
  
    /* Forms */
  
    /* Links - text */
  
    /* Buttons - background & border */
  
    /* Footer - background & text */
  
    /* Sign in tabs */
    
    /* Copied over from V1 for Settings pages */
    /*  - should be reviewed to see if possible to cleanup */ /* can't have alpha channel - used for collapsed table borders */
}

.tag-label {
  margin: 2px;
}

.action-comment {
  padding-top: 25px;
  padding-left: 5px;
  padding-right: 5px;
  text-align: left;
}

.action-comment-title {
  display: block;
}

.action-taken {
  float: left;
  font-weight: bold;
}

.action-date {
  float: right;
  color: darkGrey;
}

.action-failed {
  font-weight: normal;
}

span.failed-action {
  color: #ff8c00;
}

span.completed-action-fail {
  color: #f55841;
}

span.completed-action-pass {
  color: #7acb71;
}
