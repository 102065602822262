:import('../variables/colors.css') {
  i__const_mediumGray_16: mediumGray;
}
:export {
  colors: '../variables/colors.css';
  mediumGray: i__const_mediumGray_16;
}
.empty {
  padding: 20px !important;
  text-align: center;
  vertical-align: middle !important;
  color: i__const_mediumGray_16;
}
