:root {

    /* Generic multi-use */
  
    /* Helix Brand Colors */
  
    /* Typography - all around most common text colors */
  
    /* Grey on Grey (general borders/backgrounds) */
  
    /* "Fill layout" background */
  
    /* Modal overlay */
  
    /* Forms */
  
    /* Links - text */
  
    /* Buttons - background & border */
  
    /* Footer - background & text */
  
    /* Sign in tabs */
    
    /* Copied over from V1 for Settings pages */
    /*  - should be reviewed to see if possible to cleanup */ /* can't have alpha channel - used for collapsed table borders */
}
.modal {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  overflow: auto;
  z-index: 50;
  background-color: #fff;
}
@media (min-width: 768px) {
    .modal {
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        background-color: rgba(235, 236, 236, .8);
    }
}
.modal-content {
  position: relative;
  width: 100%;
  margin: 0;
  padding: 0 0 24px;
  overflow: scroll;
}
@media (min-width: 768px) {
    .modal-content {
        width: 570px;
        margin: 0 0 10vh;
        padding: 0;
        background-color: #fff;
        -webkit-box-shadow: -6px -6px 15px 0px rgba(0, 0, 0, .06), 6px 6px 15px 0px rgba(0, 0, 0, .06);
                box-shadow: -6px -6px 15px 0px rgba(0, 0, 0, .06), 6px 6px 15px 0px rgba(0, 0, 0, .06);
    }
}
.modal-content-lg {
  width: 770px;
}
.modal-content-xl {
  width: 970px;
}
.modal-content-xxl {
  width: 80%;
  height: 70%;
  margin: 0;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.modal-message {
  padding: 32px 32px 20px;
  margin-bottom: 32px;
  font-size: 15px !important;
  line-height: 22px;
  color: black;
}
@media only screen and (max-width : 768px) {
    .modal-message {
        margin-bottom: 22px;
        padding-left: 15px;
        padding-right: 15px;
    }
}
.modal-actions {
  margin: 36px auto 0;

  /* vertical space between buttons/links on mobile */
}
@media (max-width: 767px) {
    .modal-actions > div + div {
        margin-top: 20px;
    }
}
.button-row {
  margin: 20px 0 0;
  position: relative;
}
h2.modal-header {
  font-size: 32px;
  margin: 0;
}
@media only screen and (max-width : 768px) {
    h2.modal-header {
        font-size: 24px;
    }
}
a.close-modal-link {
  position: absolute;
  top: 19px;
  right: 23px;
  width: 20px;
  height: 20px;
  text-align: center;
  line-height: 17px;
  font-size: 36px;
  color: #383838;
  text-decoration: none;
  cursor: pointer;
}
@media (min-width: 768px) {
    a.close-modal-link {
        top: 18px;
    }
}
a.close-modal-link {
    &
  :link,
  :visited,
  :hover,
  :active {
    text-decoration: none !important;
    color: #000;
    }
}
.bottom-padding-0 {
  padding-bottom: 0;
}
.gutter-0 {
  padding-left: 0;
  padding-right: 0;
}
