:root {

    /* Generic multi-use */
  
    /* Helix Brand Colors */
  
    /* Typography - all around most common text colors */
  
    /* Grey on Grey (general borders/backgrounds) */
  
    /* "Fill layout" background */
  
    /* Modal overlay */
  
    /* Forms */
  
    /* Links - text */
  
    /* Buttons - background & border */
  
    /* Footer - background & text */
  
    /* Sign in tabs */
    
    /* Copied over from V1 for Settings pages */
    /*  - should be reviewed to see if possible to cleanup */ /* can't have alpha channel - used for collapsed table borders */
}
.panels-list {
  height: 672px;
  overflow: scroll;
  background: #f1f2f2;
}
.panels-content {
  height: 672px;
  background: #f1f2f2;
}
.title {
  font-size: 18px;
  font-weight: bold;
  padding: 14px;
  color: #383838;
  background: #d2d2d2;
}
