:root {

    /* Generic multi-use */
  
    /* Helix Brand Colors */
  
    /* Typography - all around most common text colors */
  
    /* Grey on Grey (general borders/backgrounds) */
  
    /* "Fill layout" background */
  
    /* Modal overlay */
  
    /* Forms */
  
    /* Links - text */
  
    /* Buttons - background & border */
  
    /* Footer - background & text */
  
    /* Sign in tabs */
    
    /* Copied over from V1 for Settings pages */
    /*  - should be reviewed to see if possible to cleanup */ /* can't have alpha channel - used for collapsed table borders */
}
.login {
  width: 420px;
  margin: 0 auto;
  background-color: #f5f5f6;
  border: 2px solid #e3e3e3;
  border-radius: 4px;
}
.unauthorizedUserDialog {
  width: 350px;
  margin-left: 30px;
  font-size: 12px;
  text-align: center;
}
.waiting {
  .loading {
    display: block;
  }
}
.alternative {
  background: #fff;
  padding: 12px 8px;
  font-size: 16px;
  text-align: center;
}
.alternative-link {
  color: #2196f3;
  font-weight: bold;
  cursor: pointer;
}
.loading {
  display: none;
  text-align: center;
  margin: 24px auto;
}
.email-container {
  padding: 16px 12px;
  border-bottom: 2px solid #e3e3e3;
}
.google-container {
  padding: 16px 12px;
}
.header {}
.heading {
  font-size: 21px;
  font-weight: 700;
  line-height: 24px;
  padding: 16px 12px;
  text-align: center;
}
.input {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  padding: 0.75rem 1rem;
  border: 2px solid #c3c8ce;
  border-radius: 4px;
  width: 100%;

  background-color: #fff;
  color: #333f48;
  margin-bottom: 0.75rem;
}
.message {
  color: #ed193a;
  font-size: 12px;
}
.button {
  display: block;
  width: 100%;
  padding: 0.75rem 1.25rem;
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
  text-decoration: none;
  text-align: center;
  border-radius: 4px;
  border-style: solid;
  border-width: 2px;
  cursor: pointer;
  -webkit-transition: 0.1s background-color ease;
  transition: 0.1s background-color ease;
  color: #fff;
  background: #0f9d58;
  border-color: #0f9d58;
}
.button:hover {
    background: #0b6e3e;
    border-color: #0b6e3e;
}
.hint {
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
  color: #b4bac1;
}
