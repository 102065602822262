:root {

    /* Generic multi-use */
  
    /* Helix Brand Colors */
  
    /* Typography - all around most common text colors */
  
    /* Grey on Grey (general borders/backgrounds) */
  
    /* "Fill layout" background */
  
    /* Modal overlay */
  
    /* Forms */
  
    /* Links - text */
  
    /* Buttons - background & border */
  
    /* Footer - background & text */
  
    /* Sign in tabs */
    
    /* Copied over from V1 for Settings pages */
    /*  - should be reviewed to see if possible to cleanup */ /* can't have alpha channel - used for collapsed table borders */
}

.is-selected {
  background: #d2d2d2 !important;
}

.analysis-details {
  font-size: 12px;
  line-height: 12px;
  padding: 10px;
  background: #d2d2d2
}

.analysis-details .detail-title {
    font-size: 10px !important;
    width: 20%;
    text-transform: uppercase;
    color: #666666;
}

.analysis-details .delivered-status {
    float: right;
    font-weight: bold;
    padding: 25px;
    text-transform: uppercase;
    color: rgb(244, 91, 131);
}

.analysis-details tr:first-child td {
    padding-top: 15px;
}

.analysis-details tr:last-child td {
    padding-bottom: 15px;
}

.analysis-details td {
    padding: 8px;
}

.analysis-details .analysis-selector {
    height: 30px;
    width: 70%;
}

.card {
  margin-top: 5px;
  background: white;
}

.tab-header {
  display: block;
}

.tab {
  width: 50%;
  height: 50px;
  padding-top: 15px;
  text-align: center;
  background: white;
  color: #666666
}

.tab.is-selected {
    font-weight: bold;
    border-top: 3px solid black;
    color: black;
}

.tab.current {
    float: left;
}

.tab.past {
    float: right;
}
