:import('../variables/colors.css') {
  i__const_lightGray_10: lightGray;
  i__const_mediumGray_11: mediumGray;
  i__const_darkGray_12: darkGray;
  i__const_darkBlue_13: darkBlue;
  i__const_red_14: red;
  i__const_green_15: green;
}
:export {
  colors: '../variables/colors.css';
  lightGray: i__const_lightGray_10;
  mediumGray: i__const_mediumGray_11;
  darkGray: i__const_darkGray_12;
  darkBlue: i__const_darkBlue_13;
  red: i__const_red_14;
  green: i__const_green_15;
}
table.quants-table {
  display: block;
}
.quants-table thead,
.quants-table tbody,
.quants-table tfoot,
.quants-table tr,
.quants-table td,
.quants-table th {
  display: block;
  width: 100%;
  text-align: left;
}
.quants-table tr:after {
  content: ' ';
  display: block;
  visibility: hidden;
  clear: both;
}
.quants-table tbody {
  max-height: 330px;
  overflow-y: auto;
}
.quants-table td,
.quants-table th {
  float: left;
}
.quants-table td {
  word-wrap: break-word;
  vertical-align: middle !important;
}
.quants-table td.passed,
.quants-table th.passed {
  width: 10%;
  text-align: center;
}
.quants-table td.analyzedAt,
.quants-table th.analyzedAt {
  width: 15%;
}
.quants-table td.quantType ,
.quants-table th.quantType {
  width: 20%;
}
.quants-table td.concentration,
.quants-table th.concentration {
  width: 15%;
}
.quants-table td.comments,
.quants-table th.comments {
  width: 40%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.passed-ok {
  color: i__const_green_15;
}
.passed-fail {
  color: i__const_red_14;
}
.borderless td,
.borderless th {
  border: none !important;
}
.tableHeader {
  margin-top: 2px;
  font-size: 12px;
  font-weight: normal;
  text-transform: uppercase;
  color: i__const_darkGray_12;
}
.quants-table tbody tr {
  font-size: 14px;
  border-bottom: 1px i__const_mediumGray_11 solid;
}
.quants-table td.empty {
  padding: 20px !important;
  text-align: center !important;
  vertical-align: middle !important;
  color: i__const_mediumGray_11;
}
