:import('../variables/colors.css') {
  i__const_darkBlue_37: darkBlue;
}
:export {
  colors: '../variables/colors.css';
  darkBlue: i__const_darkBlue_37;
}
.footer {
  width: 100%;
  position: fixed;
  bottom: 0px;
  left: 0px;
  background: white;
  display: block;
  padding: 25px !important;
}
.selected-count {
  color: i__const_darkBlue_37;
}
.multi-select-cancel {
  float: right;
  margin-top: -7px;
}
.multi-select-pass {
  float: right;
  margin-right: 20px;
  margin-top: -7px;
}
