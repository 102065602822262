:root {

    /* Generic multi-use */
  
    /* Helix Brand Colors */
  
    /* Typography - all around most common text colors */
  
    /* Grey on Grey (general borders/backgrounds) */
  
    /* "Fill layout" background */
  
    /* Modal overlay */
  
    /* Forms */
  
    /* Links - text */
  
    /* Buttons - background & border */
  
    /* Footer - background & text */
  
    /* Sign in tabs */
    
    /* Copied over from V1 for Settings pages */
    /*  - should be reviewed to see if possible to cleanup */ /* can't have alpha channel - used for collapsed table borders */
}

.icon > span {
  display: inline;
  color: #f1f2f2;
}

.claimed > span {
  color: #33cc33;
}

.unclaimed > span {
  color: #d2d2d2;
}

.unclaimed:hover > span {
  cursor: pointer;
  color: #99e699;
}

.unowned > span {
  color: #ffd700;
}

.unowned:hover > span {
  color: #ff8c00;
}
