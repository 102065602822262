:root {

    /* Generic multi-use */
  
    /* Helix Brand Colors */
  
    /* Typography - all around most common text colors */
  
    /* Grey on Grey (general borders/backgrounds) */
  
    /* "Fill layout" background */
  
    /* Modal overlay */
  
    /* Forms */
  
    /* Links - text */
  
    /* Buttons - background & border */
  
    /* Footer - background & text */
  
    /* Sign in tabs */
    
    /* Copied over from V1 for Settings pages */
    /*  - should be reviewed to see if possible to cleanup */ /* can't have alpha channel - used for collapsed table borders */
}

.borderless td,
.borderless th {
  border: none !important;
}

/* Table styles */

table.reviews {
  display: block;
  width: 100%;
  white-space: nowrap;
}

.reviews thead,
.reviews tbody,
.reviews tfoot,
.reviews tr,
.reviews td,
.reviews th {
  display: block;
}

.reviews tr:after {
  content: ' ';
  display: block;
  visibility: hidden;
  clear: both;
}

.reviews tbody {
  max-height: 438px;
  overflow-y: auto;
}

.reviews td,
.reviews th {
  float: left;
}

.reviews tbody tr {
  font-size: 11px;
  border-bottom: 1px #d2d2d2 solid;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.reviews td {
  vertical-align: middle !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
  margin-top: auto;
  margin-bottom: auto;
}

.reanalyzed-icon {
  color: rgb(244, 91, 131);
}

.tableHeader {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  font-size: 10px;
  font-weight: normal;
  text-transform: uppercase;
  color: #666666;
}

.reviews tr.selected {
  background-color: #f1f2f2;
}

.reviews span.autoQcStatus-QcFailed,
.reviews span.lastReviewAction-Fail {
  color: #f55841;
}

.reviews span.autoQcStatus-QcPassed,
.reviews span.lastReviewAction-Pass {
  color: #7acb71;
}

/* Comments */

.reviews tr.hasComments {
  border-right: 5px #2196f3 solid;
}

.fading {
  /* Animation takes slightly longer than the delay before hiding a row so that we give some time
  for the row dismiss action to be dispatched and acted upon */
  animation: fadeOut 0.50s;
  -webkit-animation: fadeOut 0.50s;
  -moz-animation: fadeOut 0.50s;
  -ms-animation: fadeOut 0.50s;
}

@-webkit-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    background: #f1f2f2;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    background: #f1f2f2;
  }
}

/* base analysis reviews widths */

/* for expert role - the base column widths take up 69% of table, leaving 21% availalbe for different implementations */

.reviews td.autoQcStatus,
.reviews th.autoQcStatus {
  width: 4%;
  padding: 0px;
  text-align: center;
}

.expert .sampleId {
    width: 22%;
}

.expert .completionStatus {
    width: 18%;
}

.expert .actualYield {
    text-align: center;
    padding: 0px;
    width: 10%;
}

.expert .bioSampleDateCreated {
    width: 14%;
}

/* for director and cls role - the base column widths take up 51% of table, leaving 49% availalbe for different implementations */

.cls .sampleId, .director .sampleId {
    width: 19%;
}

.cls .completionStatus, .director .completionStatus {
    width: 13%;
}

.cls .actualYield, .director .actualYield {
    text-align: center;
    padding: 0px;
    width: 8%;
}

.cls .bioSampleDateCreated, .director .bioSampleDateCreated {
    width: 10%;
}

/* implementation specific widths */

/* analysis reviews actionable table */

.reviews td.actionError,
.reviews th.actionError {
  width: 2%;
  text-align: center;
}

.reviews td.hold,
.reviews th.hold  {
  width: 2%;
  font-size: smaller;
}

.reviews td.sampleFlag,
.reviews th.sampleFlag  {
  width: 3%;
  font-size: smaller;
}

.reviews td.priority,
.reviews th.priority {
  text-align: center;
  padding: 0px;
  width: 3%;
}

.reviews td.analysisId,
.reviews th.analysisId {
  width: 3%;
  padding: 0px;
  text-align: center;
}

.reviews td.recommendation,
.reviews th.recommendation {
  text-align: center;
  padding: 0px;
  width: 11%;
}

.reviews td.actions {
  width: 23%;
  text-align: center;
}

.reviews th.actions {
  width: 15%;
  text-align: center;
}

.take-action {
  width: 60%;
  font-size: smaller;
  letter-spacing: 0.5px;
}

.edit-action {
  font-size: smaller;
}

.reviews td.actions > div:first-child {
  margin-left: 5px;
}

/* positive controls table */

.reviews td.batchName {
  width: 30%;
}

.reviews th.batchName  {
  width: 20%;
  text-align: center;
}

.sample-priority {
  color: rgb(63, 76, 119);
}

.priority {
  text-align: center;
  overflow: visible !important;
}
