:root {

    /* Generic multi-use */
  
    /* Helix Brand Colors */
  
    /* Typography - all around most common text colors */
  
    /* Grey on Grey (general borders/backgrounds) */
  
    /* "Fill layout" background */
  
    /* Modal overlay */
  
    /* Forms */
  
    /* Links - text */
  
    /* Buttons - background & border */
  
    /* Footer - background & text */
  
    /* Sign in tabs */
    
    /* Copied over from V1 for Settings pages */
    /*  - should be reviewed to see if possible to cleanup */ /* can't have alpha channel - used for collapsed table borders */
}

.search-sample-label {
  display: inline-block;
  color: #666666;
}

.search-sample-div {
  text-align: right;
  max-width: 300px;
  position: relative;
}

.sample-input {
  border-radius: 8px;
  border: 1px solid rgb(175, 175, 175);
  color: rgb(55, 55, 55);
  height: 32px;
  margin-left: 5px;
  text-align: left;
  width: 150px;
  display: inline-block;
  padding-left: 25px;
}

.search-sample-span {
  position: relative;
}

.search-icon {
  position: absolute;
  right: 130px;
  pointer-events: none;
  color: rgb(55, 55, 55);
}

input:focus {
  outline: none;
}

.submit-button {
  border-radius: 8px;
  border: 1px solid rgb(215, 215, 215);
  color: #fff;
  background-color: rgb(50, 154, 243);
  margin-left: 5px;
  margin-right: 5px;
  overflow: hidden;
  white-space: nowrap;
  height: 32px;
}
