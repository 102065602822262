.default-selector {
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 10px
}
.default-selector .default-dropdown {
  width: 100%;
}
.default-selector .item-button {
  width: calc(100% - 30px);
}
.default-selector .dropdown-toggle {
  width: 30px;
}
.default-selector .dropdown-menu {
  width: 100%;
}
