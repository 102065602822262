:import('../variables/colors.css') {
  i__const_mediumGray_27: mediumGray;
  i__const_darkGray_28: darkGray;
}
:export {
  colors: '../variables/colors.css';
  mediumGray: i__const_mediumGray_27;
  darkGray: i__const_darkGray_28;
}
/* Batch Review Summary Table */
.primaryBatchMetricsTable {
  /* horizontal scroll within pane because metrics do not collapse well */
  overflow-x: auto;
}
table.metrics {
  border: 1px;
  width: 100%;
  display: block;
  /* min-width sufficient to render without break at 1440px width screen */
  min-width: 550px;
}
.metrics thead,
.metrics tbody,
.metrics tfoot,
.metrics tr,
.metrics td,
.metrics th {
  display: block;
  width: 100%;
}
.metrics tr:after {
  content: ' ';
  display: block;
  visibility: hidden;
  clear: both;
}
.metrics tbody {
  /* max-height set to roughly 8 lanes */
  max-height: 400px;
  overflow-y: auto;
}
.metrics td,
.metrics th {
  float: left;
  word-wrap: break-word;
  vertical-align: middle !important;
}
.metrics td.runName,
.metrics td.flowCellId {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
/* column widths */
.metrics td.number,
.metrics th.number {
  width: 2%;
}
.metrics th.flowCellId,
.metrics td.flowCellId {
  width: 16%;
}
.metrics th.runName,
.metrics td.runName {
  width: 20%;
}
.metrics th.percentPf,
.metrics td.percentPf,
.metrics th.errorRate,
.metrics td.errorRate,
.metrics th.percentAligned,
.metrics td.percentAligned {
  width: 16%;
}
.metrics th.percentGtQ30,
.metrics td.percentGtQ30 {
  width: 10%;
}
span.deviation {
  color: i__const_darkGray_28;
}
.borderless td,
.borderless th {
  border: none !important;
}
.tableHeader {
  margin-top: 2px;
  font-size: 10px;
  font-weight: normal;
  color: i__const_darkGray_28;
}
.metrics tbody tr {
  font-size: 12px;
  border-bottom: 1px solid i__const_mediumGray_27;
}
