:import('../variables/colors.css') {
  i__const_lightGray_5: lightGray;
  i__const_mediumGray_6: mediumGray;
  i__const_darkGray_7: darkGray;
  i__const_red_8: red;
  i__const_green_9: green;
}
:export {
  colors: '../variables/colors.css';
  lightGray: i__const_lightGray_5;
  mediumGray: i__const_mediumGray_6;
  darkGray: i__const_darkGray_7;
  red: i__const_red_8;
  green: i__const_green_9;
}
table.qcMetrics-table {
  display: block;
}
.qcMetrics-table thead,
.qcMetrics-table tbody,
.qcMetrics-table tfoot,
.qcMetrics-table tr,
.qcMetrics-table td,
.qcMetrics-table th {
  display: block;
  width: 100%;
  text-align: left;
}
.qcMetrics-table tr {
  padding-left: 5px;
}
.qcMetrics-table tr:after {
  content: ' ';
  display: block;
  visibility: hidden;
  clear: both;
}
.qcMetrics-table tbody {
  max-height: 170px;
  overflow-y: auto;
}
.qcMetrics-table.withThreshold tbody {
  max-height: 330px;
}
.qcMetrics-table td,
.qcMetrics-table th {
  float: left;
}
.qcMetrics-table td {
  word-wrap: break-word;
  vertical-align: middle !important;
}
.qcMetrics-table td.shortName,
.qcMetrics-table th.shortName {
  width: 35%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.qcMetrics-table td.actualValue,
.qcMetrics-table th.actualValue {
  width: 10%;
}
.qcMetrics-table td.thresholdValue,
.qcMetrics-table th.thresholdValue {
  width: 10%;
  text-align: right;
}
.borderless td,
.borderless th {
  border: none !important;
}
.tableHeader {
  margin-top: 2px;
  font-size: 12px;
  font-weight: normal;
  text-transform: uppercase;
  color: i__const_darkGray_7;
}
.qcMetrics-table tbody tr {
  font-size: 14px;
  border-bottom: 1px i__const_mediumGray_6 solid;
}
.qcMetrics-table tr.row-failed {
  border-left: 5px i__const_red_8 solid;
}
.qcMetrics-table tr.row-passed {
  padding-left: 5px;
}
.qcMetrics-table .operator {
  color: i__const_mediumGray_6;
  margin-right: 3px;
}
.qcMetrics-table td.empty {
  padding: 20px !important;
  text-align: center;
  vertical-align: middle !important;
  color: i__const_mediumGray_6;
}
