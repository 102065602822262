:import('../variables/colors.css') {
  i__const_red_23: red;
}
:export {
  colors: '../variables/colors.css';
  red: i__const_red_23;
}
.tooltip-actionError > div:first-child {
  border-right-color: i__const_red_23;
}
.tooltip-actionError > div:last-child {
  background-color: i__const_red_23;
  max-width: 300px;
  width: auto;
  text-overflow: ellipsis;
  display: block;
  border-radius: 0px;
}
span.actionError {
  color: i__const_red_23;
  font-size: 20px;
}
