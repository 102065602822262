.requeue-details-content {
  clear: both;
}

.requeue-details-row {
  padding-bottom: 4px
}

.requeue-details-row span {
  float: left;
  text-align: left;
}

.tag-label {
  margin: 2px;
}
