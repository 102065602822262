:import('../variables/colors.css') {
  i__const_lightGray_55: lightGray;
  i__const_mediumGray_56: mediumGray;
  i__const_darkGray_57: darkGray;
  i__const_darkBlue_58: darkBlue;
  i__const_red_59: red;
  i__const_green_60: green;
}
:export {
  colors: '../variables/colors.css';
  lightGray: i__const_lightGray_55;
  mediumGray: i__const_mediumGray_56;
  darkGray: i__const_darkGray_57;
  darkBlue: i__const_darkBlue_58;
  red: i__const_red_59;
  green: i__const_green_60;
}
table.actions {
  display: block;
}
.actions thead,
.actions tbody,
.actions tfoot,
.actions tr,
.actions td,
.actions th {
  display: block;
  width: 100%;
  text-align: left;
}
.actions tr {
  padding-left: 5px;
}
.actions tr:after {
  content: ' ';
  display: block;
  visibility: hidden;
  clear: both;
}
.actions tbody {
  max-height: 100px;
  overflow-y: auto;
}
.actions td,
.actions th {
  float: left;
}
.actions td {
  word-wrap: break-word;
  vertical-align: middle !important;
}
.actions td.lastReviewStatus,
.actions th.lastReviewStatus {
  width: 15%;
}
.actions td.action,
.actions th.action {
  width: 20%;
}
.hasReason {
  color: i__const_darkBlue_58;
  text-decoration: underline;
}
.actions td.reviewedAt,
.actions th.reviewedAt {
  width: 15%;
}
.actions td.actionStatus,
.actions th.actionStatus {
  width: 0%;
  padding-left: 0px;
  padding-right: 0px;
  font-size: 25px;
  line-height: 10px;
  color: i__const_red_59;
}
.actions td.tags,
.actions th.tags {
  width: 25%;
}
.actions td.comments,
.actions th.comments {
  width: 25%;
}
.borderless td,
.borderless th {
  border: none !important;
}
.tableHeader {
  margin-top: 2px;
  font-size: 12px;
  font-weight: normal;
  text-transform: uppercase;
  color: i__const_darkGray_57;
}
.actions tbody tr {
  font-size: 14px;
}
.actions tr.row-failed.withBorder {
  border-left: 5px i__const_red_59 solid;
}
.actions tr.row-passed.withBorder {
  padding-left: 5px;
}
.actions td.empty {
  padding: 20px !important;
  text-align: center !important;
  vertical-align: middle !important;
  color: i__const_mediumGray_56;
}
