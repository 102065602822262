:root {

    /* Generic multi-use */
  
    /* Helix Brand Colors */
  
    /* Typography - all around most common text colors */
  
    /* Grey on Grey (general borders/backgrounds) */
  
    /* "Fill layout" background */
  
    /* Modal overlay */
  
    /* Forms */
  
    /* Links - text */
  
    /* Buttons - background & border */
  
    /* Footer - background & text */
  
    /* Sign in tabs */
    
    /* Copied over from V1 for Settings pages */
    /*  - should be reviewed to see if possible to cleanup */ /* can't have alpha channel - used for collapsed table borders */
}

.form-section {
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: left;
}

.form-section textarea {
  max-width: 100%;
  min-width: 100%;
  border-color: #c6d0dc !important
}

.form-section textarea:focus {
    outline: none;
    -webkit-box-shadow: none;
            box-shadow: none;
    webkit-box-shadow: none;
}

.reason-error {
  color: #f55841;
  font-weight: bold;
  text-align: center;
}
