:import('../variables/colors.css') {
  i__const_lightGray_17: lightGray;
  i__const_panelBackground_18: panelBackground;
}
:export {
  colors: '../variables/colors.css';
  lightGray: i__const_lightGray_17;
  panelBackground: i__const_panelBackground_18;
}
.panel {
  background: i__const_panelBackground_18;
  height: 672px;
  margin-bottom: 20px !important;
}
table {
  background-color: white;
  position: relative;
  margin-bottom: 0 !important;
}
thead {
  border-bottom: 4px i__const_lightGray_17 solid !important;
}
.batch-dash {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.batch-dash > div {
  margin: 10px;
}
.batches-table {
  -webkit-box-flex: 4;
      -ms-flex-positive: 4;
          flex-grow: 4;
  -ms-flex-negative: 4;
      flex-shrink: 4;
  -ms-flex-preferred-size: auto;
      flex-basis: auto;
  max-width: 325px;
}
.batch-details-wrapper {
  -webkit-box-flex: 8;
      -ms-flex: 8;
          flex: 8;
}
.analysis-details-wrapper {
  -webkit-box-flex: 5;
      -ms-flex: 5;
          flex: 5;
}
