:import('../variables/colors.css') {
  i__const_lightGray_39: lightGray;
  i__const_mediumGray_40: mediumGray;
  i__const_darkGray_41: darkGray;
  i__const_red_42: red;
  i__const_green_43: green;
}
:export {
  colors: '../variables/colors.css';
  lightGray: i__const_lightGray_39;
  mediumGray: i__const_mediumGray_40;
  darkGray: i__const_darkGray_41;
  red: i__const_red_42;
  green: i__const_green_43;
}
table.metrics {
  display: block;
}
.metrics thead,
.metrics tbody,
.metrics tfoot,
.metrics tr,
.metrics td,
.metrics th {
  display: block;
  width: 100%;
}
.metrics tr:after {
  content: ' ';
  display: block;
  visibility: hidden;
  clear: both;
}
.metrics tbody {
  max-height: 170px;
  overflow-y: auto;
}
.metrics.withThreshold tbody {
  max-height: 330px;
}
.metrics td,
.metrics th {
  float: left;
}
.metrics td {
  word-wrap: break-word;
  vertical-align: middle !important;
}
.metrics td.name,
.metrics th.name {
  width: 50%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.metrics td.value,
.metrics th.value {
  width: 20%;
}
.metrics td.threshold,
.metrics th.threshold {
  width: 30%;
  text-align: right;
}
.borderless td,
.borderless th {
  border: none !important;
}
.tableHeader {
  margin-top: 2px;
  font-size: 10px;
  font-weight: normal;
  text-transform: uppercase;
  color: i__const_darkGray_41;
}
.metrics tbody tr {
  font-size: 12px;
  border-bottom: 1px i__const_mediumGray_40 solid;
}
.metrics tr.row-failed {
  border-left: 5px i__const_red_42 solid;
}
.metrics tr.row-passed {
  padding-left: 5px;
}
.metrics .operator {
  color: i__const_mediumGray_40;
  margin-right: 3px;
}
.metrics td.empty {
  padding: 20px !important;
  text-align: center;
  vertical-align: middle !important;
  color: i__const_mediumGray_40;
}
