:import('../variables/colors.css') {
  i__const_orange_0: orange;
  i__const_mediumGray_1: mediumGray;
  i__const_darkGray_2: darkGray;
  i__const_red_3: red;
  i__const_green_4: green;
}
:export {
  colors: '../variables/colors.css';
  orange: i__const_orange_0;
  mediumGray: i__const_mediumGray_1;
  darkGray: i__const_darkGray_2;
  red: i__const_red_3;
  green: i__const_green_4;
}
table.requeueHistory-table {
  display: block;
}
.requeueHistory-table thead,
.requeueHistory-table tbody,
.requeueHistory-table tfoot,
.requeueHistory-table tr,
.requeueHistory-table td,
.requeueHistory-table th {
  display: block;
  width: 100%;
  text-align: left;
}
.requeueHistory-table tr:after {
  content: ' ';
  display: block;
  visibility: hidden;
  clear: both;
}
.requeueHistory-table tbody {
  max-height: 330px;
  overflow-y: auto;
}
.requeueHistory-table td,
.requeueHistory-table th {
  float: left;
}
.requeueHistory-table td {
  word-wrap: break-word;
  vertical-align: middle !important;
}
.requeueHistory-table td.dateCreated,
.requeueHistory-table th.dateCreated{
  width: 15%;
}
.requeueHistory-table td.requeueType,
.requeueHistory-table th.requeueType{
  width: 25%;
}
.requeueHistory-table td.requestedYield,
.requeueHistory-table th.requestedYield{
  width: 20%;
}
.requeueHistory-table td.bsStatus,
.requeueHistory-table th.bsStatus{
  width: 10%;
}
.requeueHistory-table td.limsStatus,
.requeueHistory-table th.limsStatus{
  width: 10%;
}
.bsStatus,
.limsStatus {
  text-align: center !important;
}
.status-passed {
  color: i__const_green_4;
}
.status-failed {
  color: i__const_red_3;
}
.status-unknown {
  color: i__const_orange_0;
}
.borderless td,
.borderless th {
  border: none !important;
}
.tableHeader {
  margin-top: 2px;
  font-size: 12px;
  font-weight: normal;
  text-transform: uppercase;
  color: i__const_darkGray_2;
}
.requeueHistory-table tbody tr {
  font-size: 14px;
  border-bottom: 1px i__const_mediumGray_1 solid;
}
.requeueHistory-table td.empty {
  padding: 20px !important;
  text-align: center !important;
  vertical-align: middle !important;
  color: i__const_mediumGray_1;
}
