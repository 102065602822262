:root {

    /* Generic multi-use */
  
    /* Helix Brand Colors */
  
    /* Typography - all around most common text colors */
  
    /* Grey on Grey (general borders/backgrounds) */
  
    /* "Fill layout" background */
  
    /* Modal overlay */
  
    /* Forms */
  
    /* Links - text */
  
    /* Buttons - background & border */
  
    /* Footer - background & text */
  
    /* Sign in tabs */
    
    /* Copied over from V1 for Settings pages */
    /*  - should be reviewed to see if possible to cleanup */ /* can't have alpha channel - used for collapsed table borders */
}

.history-lookup-dashboard {
  background: #EAEAEA;
  padding: 10px;
  margin-bottom: 20px !important;
}

.analysis-details {
  max-width: 400px;
  position: absolute;
}

.analysis-review-header {
  font-size: 14px;
  padding: 10px;
  background: #d2d2d2;
  margin: 10px 0;
}

.no-analyses-found-msg {
  padding-top: 5px;
  padding-left: 10px;
  font-weight: bold;
  color: rgb(240, 89, 67);
}

.analyses-found-msg {
  padding-top: 5px;
  padding-left: 10px;
  font-weight: bold;
  color: #0f9d58;
}

.analysis-selector {
  padding-left: 10px;
  padding-top: 5px;
}

.analysis-selector-dropdown {
  margin-left: 30px;
}
