.history-tabs {
  padding-bottom: 10px;
  padding-top: 10px;
}

.requeue-column > div {
  width: 85%;
  margin-left: auto;
  margin-right: auto;
}

.title {
  font-weight: bold;
  text-transform: uppercase;
  font-size: 12px;
  text-align: left;
  margin-bottom: 5px;
}
