:import('../variables/colors.css') {
  i__const_mediumGray_24: mediumGray;
  i__const_darkGray_25: darkGray;
  i__const_red_26: red;
}
:export {
  colors: '../variables/colors.css';
  mediumGray: i__const_mediumGray_24;
  darkGray: i__const_darkGray_25;
  red: i__const_red_26;
}
table {
  border: 1px;
  width: 100%;
}
/* Batch Review Summary Table */
table.metrics {
  display: block;
}
.metrics thead,
.metrics tbody,
.metrics tfoot,
.metrics tr,
.metrics td,
.metrics th {
  display: block;
  width: 100%;
}
.metrics tr:after {
  content: ' ';
  display: block;
  visibility: hidden;
  clear: both;
}
.metrics tbody {
  max-height: 100px;
  overflow-y: auto;
}
.metrics td,
.metrics th {
  float: left;
  word-wrap: break-word;
  vertical-align: middle !important;
}
.metrics td.name,
.metrics th.name {
  width: 55%;
}
.metrics th.pipelineType,
.metrics td.pipelineType,
.metrics th.value,
.metrics td.value,
.metrics th.percentage,
.metrics td.percentage {
  width: 15%;
}
.borderless td,
.borderless th {
  border: none !important;
}
.tableHeader {
  margin-top: 2px;
  font-size: 10px;
  font-weight: normal;
  text-transform: uppercase;
  color: i__const_darkGray_25;
}
.metrics tbody tr {
  font-size: 12px;
  border-bottom: 1px solid i__const_mediumGray_24;
}
.metrics .statFailed {
  border-left: 5px solid i__const_red_26;
}
