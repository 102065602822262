:root {

    /* Generic multi-use */
  
    /* Helix Brand Colors */
  
    /* Typography - all around most common text colors */
  
    /* Grey on Grey (general borders/backgrounds) */
  
    /* "Fill layout" background */
  
    /* Modal overlay */
  
    /* Forms */
  
    /* Links - text */
  
    /* Buttons - background & border */
  
    /* Footer - background & text */
  
    /* Sign in tabs */
    
    /* Copied over from V1 for Settings pages */
    /*  - should be reviewed to see if possible to cleanup */ /* can't have alpha channel - used for collapsed table borders */
}

/* Table styles */

table.batches {
  display: block;
  width: 100%;
}

table.batchReviewsTable {
  width: 105%;
}

.batch-refresh {
  color: #33cc33;
}

.batch-priority {
  color: rgb(63, 76, 119);
}

.labPriority {
  width: 10%;
  text-align: center;
}

.batches thead,
.batches tbody,
.batches tfoot,
.batches tr,
.batches td,
.batches th {
  display: block;
}

.batches tr:after {
  content: ' ';
  display: block;
  visibility: hidden;
  clear: both;
}

.batches tbody {
  max-height: 624px;
  overflow-y: auto;
}

.batches td,
.batches th {
  float: left;
}

.batches tbody tr {
  font-size: 12px;
  border-bottom: 1px #d2d2d2 solid;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.batches td {
  vertical-align: middle !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: auto;
  margin-bottom: auto;
}

.batches td.claim,
.batches th.claim {
  width: 5%;
}

.batches td.name,
.batches th.name {
  width: 60%;
}

.batches td.modifiedAt,
.batches th.modifiedAt {
  width: 20%;
  text-align: end;
}

.batches td.actionError,
.batches th.actionError {
  width: 5%;
  padding: 0px;
  text-align: center;
}

.batches td.pool-hold,
.batches th.pool-hold {
  width: 5%;
}

.reanalyzed-icon {
  color: rgb(244, 91, 131);
}

.borderless td,
.borderless th {
  border: none !important;
}

.tableHeader {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  font-size: 10px;
  font-weight: normal;
  text-transform: uppercase;
  color: #666666;
}

.batches tr.selected {
  background-color: #f1f2f2;
}

.fading {
  /* Animation takes slightly longer than the delay before hiding a row so that we give some time
  for the row dismiss action to be dispatched and acted upon */
  animation: fadeOut 0.50s;
  -webkit-animation: fadeOut 0.50s;
  -moz-animation: fadeOut 0.50s;
  -ms-animation: fadeOut 0.50s;
}

@-webkit-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    background: #f1f2f2;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    background: #f1f2f2;
  }
}
