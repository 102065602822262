:import('../variables/colors.css') {
  i__const_orange_51: orange;
  i__const_darkGray_52: darkGray;
  i__const_red_53: red;
  i__const_green_54: green;
}
:export {
  colors: '../variables/colors.css';
  orange: i__const_orange_51;
  darkGray: i__const_darkGray_52;
  red: i__const_red_53;
  green: i__const_green_54;
}
.tableHeader {
  margin-top: 2px;
  font-size: 10px;
  font-weight: normal;
  text-transform: uppercase;
  color: i__const_darkGray_52;
}
.requeueHistory {
  max-height: 330px;
  overflow-y: auto;
}
.requeueHistory-table td,
  .requeueHistory-table th {
  text-align: left;
  border: none !important;
}
.requeueHistory-table tbody tr {
  font-size: 12px;
}
.requeueHistory-table tfoot td {
  text-align: center;
}
.requeueHistory-table tr.selected > td.requeueType {
  font-weight: bold;
}
.bsStatus,
.limsStatus {
  text-align: center !important;
}
.status-passed {
  color: i__const_green_54;
}
.status-failed {
  color: i__const_red_53;
}
.status-unknown {
  color: i__const_orange_51;
}
